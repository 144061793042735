<template>
  <div>

    <div class="d-flex justify-start align-center" style="height:50px;color: #d31145;background-color:#fff;padding: 0 20px 0 20px;">
      <v-icon color="#d31145" @click="$router.push('/master')">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700">Pegawai</span>
    </div>

    <v-divider></v-divider>

    <div>
      <div v-if="list.data.length > 0 || searching != ''" class="pa-4" style="background-color:#ffffff;height:60px">
        <v-text-field
          dense
          v-model="searching"
          :hide-details="true"
          color="#ACACAC"
          filled
          rounded
          placeholder="Search..."
          elevation="0">
          <template v-slot:prepend-inner>
            <v-icon 
              color="#ACACAC">
              mdi-magnify
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon 
              color="#FF2353"  
              v-show="searching != ''"
              @click="
                searching = '';
                initialize()">
              mdi-close-circle
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-card flat v-if="list.data.length < 1 && searching == '' && !process.run">
        <v-card-text style="padding: 20px 20px">
          <div style="font-weight:700;font-size:16pxline-height: 19px;padding-bottom: 5px">
            Anda belum menambahkan Pegawai
          </div>

          <div style="font-weight: normal;font-size: 14px;color:#ACACAC;line-height: 16px;padding-bottom: 5px">
            Tambahkan data pegawai untuk mengelola toko yang Anda miliki
          </div>

          <div style="padding-top:20px">
            <v-btn to="/master/user/form" rounded depressed color="#d31145" elevation="0" class="white--text text-capitalize">
              <v-icon>mdi-plus</v-icon>
              Tambahkan
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card flat v-else-if="list.data.length < 1 && searching != ''">
        <v-card-text
          class="d-flex align-center"
          style="height: calc(100vh - 200px); background: #f9f9f9; ">
            <v-img
              class="mx-auto"
              max-width="200"
              src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
            </v-img>
        </v-card-text>
      </v-card>

      <v-card flat v-else>
        <v-card-text class="px-0 pb-0 py-0" style="overflow:auto;height:calc(100vh - 120px);background: #f5f5f5">

          <div v-if="list.data.length < 1 && searching == '' && process.run">
            <v-skeleton-loader
              v-for="item in 15" :key="item"
              type="list-item-avatar-two-line">
            </v-skeleton-loader>
          </div>

          <v-list subheader two-line class="py-0" dense>
            <template v-for="(item, i) in list.data">
              <v-list-item :key="item.id">
                <v-list-item-avatar tile size="50">
                  <v-img
                    width="50"
                    height="50"
                    class="rounded-lg"
                    :src="item.image_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="#d31145">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content @click="getDetail(item,'detail')" class="cursor-pointer">
                  <v-list-item-title style="font-size: 16px;">
                    {{ item.fullname }}
                    <!-- <v-icon @click="getDetail(item,'detail')" color="#FFA117" small>mdi-information-outline</v-icon> -->
                  </v-list-item-title>
                  <v-list-item-subtitle style="color:#d31145">
                    {{ item.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div style="display:flex">
                    <v-btn icon :to="`/master/user/form?id=${item.id}`">
                      <v-icon color="#d31145">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="getDetail(item,'delete')">
                      <v-icon color="#d31145">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="i" class="mx-auto"></v-divider>
            </template>
            
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <v-btn v-if="list.data.length > 0" class="float white--text text-capitalize" rounded color="#d31145" to="/master/user/form">
      <v-icon color="#fff" class="my-float" left>
        mdi-plus
      </v-icon>
      Tambahkan
    </v-btn>

    <!-- <v-dialog v-model="dialog.selectStore" scrollable persistent width="350">
      <v-card rounded="lg">

        <v-card-title style="background-color:#d31145;color:#fff">
          <div v-if="dialog.selection == 'store'">
            Plih Toko ({{ user.profile ? user.profile.store_array.length : 0 }})
          </div>
          <div v-if="dialog.selection == 'role'">
            Plih Toko ({{ user.profile ? user.profile.store_array.length : 0 }})
          </div>

          <v-spacer></v-spacer>

          <v-icon @click="dialog.selectStore = false" color="#fff">mdi-close-circle-outline</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        
        <v-card-text class="px-0" style="height:300px">

          <v-card
            flat
            class="mx-auto">

            <v-list v-if="dialog.selection == 'store'" class="py-0">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in user.profile ? user.profile.store_array : []"
                  :key="i"
                  @click="AddStore(item,i)">

                  <v-list-item-avatar>
                    <img :src="item.image_url == '' ? require('@/assets/images/icon_store.png') : item.image_url" alt="" width="30">
                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title v-text="item.name"></v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>

            <v-list v-if="dialog.selection == 'role'">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in role"
                  :key="i"
                  @click="updateRole(item,i)">

                  <v-list-item-icon>
                    <v-icon color="#d31145">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>

                    <v-list-item-title class="text-capitalize">
                      {{ item.name }}
                    </v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>
                    
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>
      
      </v-card>
    </v-dialog>

    <v-dialog v-model="action.confirmation" persistent width="350">
      <v-card flat style="border-radius:15px">
        <v-card-title 
          class="subtitle-1 font-weight-bold white--text justify-center" 
          style="
            background-color:#d31145">

          <div v-if="form.id">
            Ubah Pegawai
          </div>

          <div v-else>
            Tambah Pegawai
          </div>
        </v-card-title>

        <v-card-text 
          class="text-center pb-0">

          <v-img
            contain
            width="100"
            height="100"
            class="mx-auto mt-3"
            :src="form.image_url">
          </v-img>

          <div class="mt-5">
            {{ form.fullname }}
          </div>

          <div>
            {{ form.email }}
          </div>

          <div>
            {{ form.mobilephone }}
          </div>

          <div>
            {{ form.address }}
          </div>

          <div style="border: 1px dashed #FA91AD;width: 305px;" class="mt-7"></div>

          <v-list dense>
            <v-list-item>
              <v-list-item-content class="text-start">
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Toko</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Role</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-for="(item,i) in form.store_array" :key="i" class="py-0">
              <v-list-item-content class="text-start">
                <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.role }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>

        <v-card-actions class="pb-8 px-3">

          <v-btn 
          @click="save()" 
          :loading="process.run" 
          :disabled="process.run"
          rounded
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text">

          <v-icon color="white" left>
            mdi-check-circle-outline
          </v-icon>
          <span class="subtitle-2">
            Simpan Data
          </span>
        </v-btn>

        <v-btn 
          @click="action.confirmation = false"
          :loading="process.run" 
          :disabled="process.run" 
          rounded 
          style="flex:1"
          outlined
          elevation="0" 
          color="#A8A8A8" 
          class="text-capitalize">

          <span>
            Batalkan
          </span>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="action.detail" persistent width="360">
      <v-card flat style="border-radius:15px">
        <v-card-title class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" style="background-color:#d31145">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              Data Pegawai
          </div>
          <v-icon @click="action.detail = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-card-text 
          class="text-center py-4">

          <v-img
            contain
            width="100"
            height="100"
            class="rounded-xl mx-auto mt-3"
            :src="detail.image_url">
          </v-img>

          <div class="mt-5 font-weight-bold">
            {{ detail.fullname }}
          </div>

          <div>
            {{ detail.email }}
          </div>

          <div>
            {{ detail.mobilephone }}
          </div>

          <div class="mt-3 body-2">
            {{ detail.address }}
          </div>

          <div style="border: 1px dashed #FA91AD;width: 100%;" class="mt-7"></div>

          <table class="pt-4">
            <thead>
              <tr>
                <th width="250" style="color:#d31145" class="font-weight-bold text-capitalize text-start">Toko</th>
                <th style="color:#d31145" class="font-weight-bold text-capitalize text-start">Role</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in detail.store_array" :key="i">
                <td width="250" class="text-start">{{ item.name }}</td>
                <td class="text-start text-capitalize">{{ item.role }}</td>
              </tr>
            </tbody>
          </table>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="action.delete" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
            Hapus Pegawai
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin menghapus Pegawai &nbsp;
          <span class="font-weight-bold text-title text-capitalize pl-1"> 
            {{ detail.fullname }} 
          </span> &nbsp;
          ?
        </div>

        <div class="d-flex justify-center align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="deleteData()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, Hapus Data
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="action.delete = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- <v-dialog v-model="action.delete" persistent width="350">
      <v-card flat style="border-radius:15px">
        <v-card-title class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" style="background-color:#d31145">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              Hapus Data Pegawai
          </div>
          <v-icon @click="action.delete = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-card-text class="text-center py-4">

          <v-img
            contain
            width="100"
            height="100"
            class="rounded-xl mx-auto mt-3"
            :src="detail.image_url">
          </v-img>

          <div class="mt-5">
            {{ detail.fullname }}
          </div>

          <div>
            {{ detail.email }}
          </div>

          <div>
            {{ detail.mobilephone }}
          </div>

          <div class="mt-3 body-2">
            {{ detail.address }}
          </div>

          <div style="border: 1px dashed #FA91AD;width: 100%;" class="mt-7"></div>

          <v-list dense>
            <v-list-item>
              <v-list-item-content class="text-start">
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Toko</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Role</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-for="(item,i) in detail.store_array" :key="i">
              <v-list-item-content class="text-start">
                <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle class="text-capitalize">{{ item.role }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>
        <v-card-actions class="pb-4 px-3">
          <v-btn 
          @click="deleteData()" 
          rounded
          :loading="process.run"
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text">
          <v-icon color="white" left>mdi-delete-outline</v-icon>
          <span class="subtitle-2">
            Hapus Data
          </span>
        </v-btn>
        <v-btn 
          @click="action.delete = false"
          rounded 
          :loading="process.run"
          style="flex:1"
          outlined
          elevation="0" 
          color="#A8A8A8" 
          class="text-capitalize">
          <span>
            Batalkan
          </span>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

  </div>

  <!-- <div class="py-14">
    
    <div v-if="list.data.length < 1 && !action.form"
      class="py-6">
      <v-card-title class="body-1 font-weight-bold pb-2">
        Anda belum menambahkan Pegawai
      </v-card-title>

      <v-card-text class="body-2 font-weight-light">
        Tambahkan data pegawai untuk mengelola toko yang Anda miliki
      </v-card-text>

      <v-card-text>
        <v-btn
          elevation="0"
          rounded
          color="#d31145"
          class="body-2 text-capitalize white--text"
          @click="resetForm(undefined, true)">
          <v-icon class="mr-1">mdi-plus</v-icon>

          <span>
            Tambahkan
          </span>
        </v-btn>
      </v-card-text>
    </div>

    <div v-if="list.data.length > 0 && !action.form">
      <v-card flat>
        <v-card-text class="px-0 pb-0 py-0" style="height:calc(100vh - 100px);overflow:auto;background: #f5f5f5">

          <v-list subheader two-line>
            <template v-for="(item, i) in list.data">
              <v-list-item @click="getDetail(item)" :key="item.id">
                <v-list-item-avatar tile size="50" style="border-radius:5px !important">
                  <v-img
                    width="50"
                    height="50"
                    :src="item.image_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="#d31145">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 16px;">
                    {{ item.fullname }}
                    <v-icon @click="getDetail(item,'detail')" color="#FFA117" small>mdi-information-outline</v-icon>
                  </v-list-item-title>
                  <v-list-item-subtitle style="color:#d31145">
                    {{ item.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div style="display:flex">
                    <v-btn icon @click="resetForm(item, true)">
                      <v-icon color="#d31145">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="getDetail(item,'delete')">
                      <v-icon color="#d31145">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="i" class="mx-auto"></v-divider>
            </template>
            
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <v-btn 
      v-if="list.data.length > 0 && !action.form" 
      class="float white--text text-capitalize" 
      rounded 
      color="#d31145" 
      @click="resetForm(undefined, true)">
      <v-icon color="#fff" class="my-float" left>mdi-plus</v-icon>
      Tambahkan
    </v-btn>

    <div v-if="action.form"
      class="py-6">
      <v-card-text style="height: calc(100vh - 150px);overflow:auto">
        <v-form>
          <div class="d-flex justify-center align-center py-4">
            <v-card
              flat
              class="pa-0 c-p"
              style="background: transparent;">
              <image-input v-model="avatar" type="uploadCover">
                <div slot="activator">
                  <v-avatar
                    tile
                    size="125"
                    class="grey lighten-1 cursor-pointer rounded-xl">
                    <v-img
                      v-if="!avatar && addImage === ''"
                      :src="form.image_url"
                      class="mx-auto"
                      width="125"
                      height="125"
                      gradient="to top right, rgba(0,0,0,.1), rgba(0,0,0,.1)">
                      <v-icon
                        v-if="!avatar && addImage === '' && form.image_url === ''"
                        color="white"
                        style="z-index:2"
                        size="40">
                        mdi-camera-plus
                      </v-icon>
                    </v-img>

                    <div
                      class="px-5"
                      v-if="addImage && avatar.url === undefined">
                      <v-progress-linear
                        color="#d31145"
                        indeterminate
                        rounded
                        height="6"
                        class="mx-4 mt-4"
                        style="width: 80px;">
                      </v-progress-linear>

                      <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                        Mengupload Gambar . . .
                      </p>
                    </div>

                    <v-img
                      v-if="avatar && !addImage"
                      :src="form.image_url"
                      :alt="form.image_url"
                      class="ma-auto"
                      gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
                      width="125"
                      height="125">
                      <v-icon
                        color="white"
                        size="40"
                        v-if="avatar && !addImage">
                        mdi-camera-retake
                      </v-icon>
                    </v-img>
                  </v-avatar>
                </div>
              </image-input>
            </v-card>

            <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
              {{ error.image_url }}
            </p>
          </div>

          <v-row>
            <v-col cols="12">
              <div class="mb-2 subtitle-2 font-weight-bold">
                Nama
              </div>
              <v-text-field
                v-model="form.fullname"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="50"
                autocomplete="off"
                hide-details="auto">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">

                  <span style="font-weight: bold">
                    {{ form.fullname.length }} 
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 50 karakter
                  </span>  
                </div>
              </div>
            </v-col>
            <v-col cols="12">

              <div class="mb-2 subtitle-2 font-weight-bold">
                Email
              </div>

              <v-text-field
                v-model="form.email"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="50"
                autocomplete="off"
                hide-details="auto">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">

                  <span style="font-weight: bold">
                    {{ form.email.length }} 
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 50 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">

              <div class="mb-2 subtitle-2 font-weight-bold">
                Nomor HP
              </div>

              <v-text-field
                v-model="form.mobilephone"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="15"
                autocomplete="off"
                hide-details="auto">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  
                  <span style="font-weight: bold">
                    {{ form.mobilephone.length }} 
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 15 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">

              <div class="mb-2 subtitle-2 font-weight-bold">
                Alamat
              </div>

              <v-text-field
                v-model="form.address"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="150"
                autocomplete="off"
                hide-details="auto">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Optional
                </div>

                <div style="font-size: 11px;">
                  
                  <span style="font-weight: bold">
                    {{ form.address.length }} 
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 150 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">

              <div class="mb-2 d-flex justify-space-between subtitle-2 font-weight-bold">
                Toko dan Role
                <v-btn class="text-capitalize" @click="dialog.selectStore = true;dialog.selection = 'store'" small text color="#d31145">
                  <v-icon left>mdi-plus</v-icon>
                  Tambahkan
                </v-btn>
              </div>

              <v-alert
                v-if="form.store_array.length < 1"
                width="300"
                text
                dense
                color="warning"
                icon="mdi-information-outline">
                Tambahkan Toko dan Role
              </v-alert>


              <div v-else v-for="(item, i) in form.store_array" :key="i" class="d-flex justify-space-between py-2">
                <div 
                  class="pa-1 pl-4"
                  style="
                    border: 2px solid #d31145;
                    flex:1;
                    color: #d31145;
                    border-radius: 5px 0px 0px 5px">
                  {{ item.name }}
                </div>
                <div 
                  @click="selectRole(item)"
                  class="d-flex justify-center mr-3 pa-1 white--text"
                  style="
                    cursor: pointer;
                    background-color: #d31145;
                    width: 100px;
                    border-radius: 0px 5px 5px 0px;">
                  <div>
                    <span class="text-capitalize">
                      {{ item.role }}
                    </span>
                    <v-icon small color="white" class="ml-4">mdi-pencil</v-icon>
                  </div>
                </div>
                <div>
                  <v-icon @click="removeStore(i)" color="#d31145">mdi-delete</v-icon>
                </div>
              </div>
              
            </v-col>
          </v-row>


        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center align-center text-center">
        <div style="position:absolute;bottom:20px;width:100%;" class="d-flex px-6">
          <v-btn 
            @click="toValidation()" 
            :loading="process.run" 
            :disabled="process.run"
            rounded
            style="flex:1"
            elevation="0" 
            color="#d31145" 
            class="text-capitalize white--text mr-2">
            <v-icon color="white" left>mdi-check-circle-outline</v-icon>
            <span>
              Simpan Data
            </span>
          </v-btn>
          <v-btn 
            @click="goback"
            :loading="process.run" 
            :disabled="process.run" 
            rounded 
            style="flex:1"
            outlined
            elevation="0" 
            color="#A8A8A8" 
            class="text-capitalize">
            <span>
              Batalkan
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </div>

    <v-dialog v-model="dialog.selectStore" scrollable persistent width="350">
      <v-card rounded="lg">

        <v-card-title style="background-color:#d31145;color:#fff">
          <div v-if="dialog.selection == 'store'">
            Plih Toko ({{ user.profile ? user.profile.store_array.length : 0 }})
          </div>
          <div v-if="dialog.selection == 'role'">
            Plih Toko ({{ user.profile ? user.profile.store_array.length : 0 }})
          </div>

          <v-spacer></v-spacer>

          <v-icon @click="dialog.selectStore = false" color="#fff">mdi-close-circle-outline</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        
        <v-card-text class="px-0" style="height:300px">

          <v-card
            flat
            class="mx-auto">

            <v-list v-if="dialog.selection == 'store'">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in user.profile ? user.profile.store_array : []"
                  :key="i"
                  @click="AddStore(item,i)">

                  <v-list-item-avatar>
                    <img :src="item.image_url == '' ? require('@/assets/images/icon_store.png') : item.image_url" alt="" width="30">
                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title v-text="item.name"></v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>

            <v-list v-if="dialog.selection == 'role'">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in role"
                  :key="i"
                  @click="updateRole(item,i)">

                  <v-list-item-icon>
                    <v-icon color="#d31145">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>

                    <v-list-item-title class="text-capitalize">
                      {{ item.name }}
                    </v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>
                    
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>
      
      </v-card>
    </v-dialog>

    <v-dialog v-model="action.confirmation" persistent width="350">
      <v-card flat style="border-radius:15px">
        <v-card-title 
          class="subtitle-1 font-weight-bold white--text justify-center" 
          style="
            background-color:#d31145">

          <div v-if="form.id">
            Ubah Kategori Produk
          </div>

          <div v-else>
            Tambah Kategori Produk
          </div>
        </v-card-title>

        <v-card-text 
          class="text-center pb-0">

          <v-img
            contain
            width="100"
            height="100"
            class="mx-auto mt-3"
            :src="form.image_url">
          </v-img>

          <div class="mt-5">
            {{ form.fullname }}
          </div>

          <div>
            {{ form.email }}
          </div>

          <div>
            {{ form.mobilephone }}
          </div>

          <div>
            {{ form.address }}
          </div>

          <div style="border: 1px dashed #FA91AD;width: 305px;" class="mt-7"></div>

          <v-list dense>
            <v-list-item>
              <v-list-item-content class="text-start">
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Toko</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Role</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-for="(item,i) in form.store_array" :key="i" class="py-0">
              <v-list-item-content class="text-start">
                <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.role }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>

        <v-card-actions class="pb-8 px-3">

          <v-btn 
          @click="save()" 
          :loading="process.run" 
          :disabled="process.run"
          rounded
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text">

          <v-icon color="white" left>
            mdi-check-circle-outline
          </v-icon>
          <span class="subtitle-2">
            Simpan Data
          </span>
        </v-btn>

        <v-btn 
          @click="action.confirmation = false"
          :loading="process.run" 
          :disabled="process.run" 
          rounded 
          style="flex:1"
          outlined
          elevation="0" 
          color="#A8A8A8" 
          class="text-capitalize">

          <span>
            Batalkan
          </span>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="action.detail" persistent width="350">
      <v-card flat style="border-radius:15px">
        <v-card-title 
          class="subtitle-1 font-weight-bold white--text d-flex align-center justify-center" 
          style="
            background-color:#d31145">

            <div>
              Data Pegawai
            </div>

            <v-spacer></v-spacer>
            <v-icon class="text-end" @click="action.detail = false" color="white">mdi-close-circle-outline</v-icon>
        </v-card-title>

        <v-card-text 
          class="text-center pb-0">

          <v-img
            contain
            width="100"
            height="100"
            class="mx-auto mt-3"
            :src="detail.image_url">
          </v-img>

          <div class="mt-5">
            {{ detail.fullname }}
          </div>

          <div>
            {{ detail.email }}
          </div>

          <div>
            {{ detail.mobilephone }}
          </div>

          <div>
            {{ detail.address }}
          </div>

          <div style="border: 1px dashed #FA91AD;width: 100%;" class="mt-7"></div>

          <v-list dense>
            <v-list-item>
              <v-list-item-content class="text-start">
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Toko</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Role</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-for="(item,i) in detail.store_array" :key="i">
              <v-list-item-content class="text-start">
                <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.role }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="action.delete" persistent width="350">
      <v-card flat style="border-radius:15px">
        <v-card-title 
          class="subtitle-1 font-weight-bold white--text d-flex align-center justify-center" 
          style="
            background-color:#d31145">

            <div>
              Hapus Data Pegawai
            </div>
        </v-card-title>

        <v-card-text 
          class="text-center pb-0">

          <v-img
            contain
            width="100"
            height="100"
            class="mx-auto mt-3"
            :src="detail.image_url">
          </v-img>

          <div class="mt-5">
            {{ detail.fullname }}
          </div>

          <div>
            {{ detail.email }}
          </div>

          <div>
            {{ detail.mobilephone }}
          </div>

          <div>
            {{ detail.address }}
          </div>

          <div style="border: 1px dashed #FA91AD;width: 100%;" class="mt-7"></div>

          <v-list dense>
            <v-list-item>
              <v-list-item-content class="text-start">
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Toko</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Role</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-for="(item,i) in detail.store_array" :key="i">
              <v-list-item-content class="text-start">
                <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.role }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>
        <v-card-actions class="pb-8 px-3">
          <v-btn 
          @click="deleteData()" 
          rounded
          :loading="process.run"
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text">
          <v-icon color="white" left>mdi-delete-outline</v-icon>
          <span class="subtitle-2">
            Hapus Data
          </span>
        </v-btn>
        <v-btn 
          @click="action.delete = false"
          rounded 
          :loading="process.run"
          style="flex:1"
          outlined
          elevation="0" 
          color="#A8A8A8" 
          class="text-capitalize">
          <span>
            Batalkan
          </span>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div> -->
</template>

<script>
  export default {
    data () {
      return {
        action: {
          form: false,
          confirmation: false,
          delete: false,
          detail: false,
        },
        detail: {},
        searching: "",
        list: {
          data: []
        },
        pagination: {
          data: {}
        },
        process: {
          run: false
        },
      }
    },
    watch: {
      searching: _.debounce(function(newVal) {
        this.searching = newVal;
        this.pagination.page = newVal === null ? "" : 1;

        this.initialize();
      }, 500),
    },
    computed: {
      user(){
        return this.$store.state.user
      }
    },
    components: {

    },
    created() {

    },
    mounted(){
      this.initialize()
    },
    methods: {
      getDetail(item, status){
        this.detail = item
        if (status == 'delete') {
          this.action.delete = true
        }else{
          this.action.detail = true
        }
      },

      async initialize(){
        this.process.run = true

        let params = {
          search: this.searching,
          sort: "",
          dir: "",
          limit: 1000,
          page: 1
        }

        let res = await this.$get(`user`, params)

        if (res.status == 200) {
          this.process.run = false
          this.list.data = res.results.data
          this.pagination.data = res.results.pagination
        }

        this.process.run = false
      },

      async deleteData() {
        this.$store.state.overlay.state = true
        this.process.run = true

        let data = {
          id: this.detail.id,
        }

        let res = await this.$post(`user/delete`, data)

        if (res.status == 200) {
          this.initialize()
          this.action.delete = false
          this.process.run = false
          this.$store.state.overlay.state = false
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = res.message
        }

        this.action.delete = false
        this.process.run = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        this.$store.state.overlay.state = false
      }
    }
  }
</script>